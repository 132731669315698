<template>  
    <div class="content">
        <p class="description">诗歌本是一款以歌谱为主，歌曲为辅的，以方便聚会为目的圣诗工具。目前已收录上百本全国各地的诗歌本。</p>
        <div class="os">
            <h2><span class="icon iconfont">&#xe680;&#xe681;&#xe683;</span>Android</h2>
            <p>华为（鸿蒙5需要通过卓易通安装）、荣耀、OPPO、VIVO、小米等品牌。</p>	
            <a-space>
                <a-button id="button" type="primary" @click="openHref('flutter hymn apk', flutter_hymnApkUrl)">v{{flutter_hymnApkVersion}} 下载</a-button>
                <a-button id="button" type="primary" @click="openHref('hymn apk', hymnApkUrl)">v{{hymnApkVersion}} 下载</a-button>
            </a-space>	
        </div>
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe6bb;</span>HarmonyOS NEXT</h2>
            <p>华为新系统，2024.11开始陆续推送正式版。需要下载侧载工具，首次安装需要使用Windows电脑。</p>	
            <a-space>
                <a-button id="button" type="primary" @click="openHref('flutter hymn hap', flutter_hymnHapUrl)">v{{flutter_hymnHapVersion}} 下载</a-button>
                <a-button id="button" type="primary" @click="openHref('flutter hymn hap tool', flutter_hapToolUrl)">侧载工具</a-button>
            </a-space>	
        </div>
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe685;&#xe682;</span>iPhone、iPad</h2>
            <p><span style='color: darkslateblue'>方式一：因未在中国大陆AppStore上线，需要通过<a href="https://appleid.apple.com" role="button">更改账号地区到港澳台</a>后，打开AppStore，退出账号重新登录后下载。对于更新，如不成功，请先删除之前下载的版本。</span></p>
            <p><span style='color: green'>方式二：使用轻松签、全能签或者易签助手的可以添加源地址（http://christapps.org/appstore）直接下载安装。 （ios14-15的一些设备可能可以免费使用轻松签，其他的需要购买证书。淘宝或拼多多搜 <span style='color: red'>p12签名</span>有35元质保300多天的，只能用于ipad的会便宜一点，注意甄别。一个签名只能用于一台设备。）</span></p>					
            <p><span style='color: green'>方式三：可以通过电脑使用爱思助手签名的，可以下载安装包，重新签名后使用。爱思助手支持使用自己的苹果账号免费签名，有效7天，过期再重签。</span></p>					
            <a-space>
                <a-button id="button" type="primary" @click="openHref('html', 'appleid.htm')">换区流程</a-button>	
                <a-button id="button" type="primary" @click="openHref('html', 'https://appleid.apple.com')">更换地区</a-button>		
                <a-button id="button" type="primary" @click="openHref('flutter hymn ipa', flutter_hymnIpaUrl)" v-if="flutter_hymnIpaUrl!=''">v{{flutter_hymnIpaVersion}} 下载（不会签名的不要下载）</a-button>
                <a-button id="button" type="primary" @click="openHref('hymn ipa', hymnIpaUrl)" v-if="hymnIpaUrl!=''">v{{hymnIpaVersion}} 下载</a-button>	
            </a-space>										
        </div>
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe684;</span>Windows电脑</h2>
            <p>WPF版本从Win7开始支持;UWP版本，v6版本从Win10开始支持。</p>						
            <span style='color: darkorange'>Win10系统使用Edge浏览器下载可能遇到的问题，</span><a href="qa.htm" role="button">点这里查看。</a><br>
            <span style='color: darkgoldenrod'>win10系统1803版本（18年3月）开始可以下载UWP版本，两个版本不冲突可以同时安装，哪个好用用哪个。安装前需要在设置里打开“开发人员模式”，同时安装证书，</span><a href="qa2.htm" role="button">点这里可以查看图文说明。</a><br>
            <a-space>
                <a-button id="button" type="primary" @click="openHref('flutter hymn exe', flutter_hymnExeUrl)">v{{flutter_hymnExeVersion}} 下载</a-button>
                <a-button id="button" type="primary" @click="openHref('hymn exe', hymnExeUrl)">WPF v{{hymnExeVersion}} 下载</a-button>
                <a-button id="button" type="primary" @click="openHref('hymn appx', hymnAppxUrl)">UWP v{{hymnAppxVersion}} 下载</a-button>
            </a-space>	
        </div>
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe67f;</span>苹果电脑</h2>
            <p>从10.15版本开始支持。</p>
            <a-space>	
                <a-button id="button" type="primary" @click="openHref('flutter hymn mac', flutter_hymnDmgUrl)">v{{flutter_hymnDmgVersion}} 下载</a-button>
                <a-button id="button" type="primary" @click="openHref('hymn mac', hymnDmgUrl)">v{{hymnDmgVersion}} 下载</a-button>
            </a-space>	
        </div>     
        <div class='divider'></div>
        <div class="os">
            <h2><span class="icon iconfont">&#xe6af;</span>Linux电脑</h2>
            <p>Deepin、UOS上可以使用，其他版本请自行尝试。</p>
            <a-space>	
                <a-button id="button" type="primary" @click="openHref('flutter hymn mac', flutter_hymnLinuxUrl)">v{{flutter_hymnLinuxVersion}} 下载</a-button>
            </a-space>	
        </div>                      
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    props: {

    },
    data() {
        return {
            showWechat: false,
            hymnApkVersion: '5.4',
            hymnApkUrl: '/app/hymn.apk',
            hymnExeVersion: '4.7',
            hymnExeUrl: '/app/hymn.exe',
            hymnAppxVersion: '4.7',
            hymnAppxUrl: '/app/hymn.appxbundle',
            hymnDmgVersion: '4.7',
            hymnDmgUrl: '/app/hymn.dmg',
            hymnIpaVersion: '4.7',
            hymnIpaUrl: '/app/hymn.ipa',
            flutter_hymnApkVersion: '0.4',
            flutter_hymnApkUrl: '/app/flutter_hymn.apk',
            flutter_hymnHapVersion: '0.4',
            flutter_hymnHapUrl: '/app/hymn.hap',
            flutter_hapToolUrl: '',
            flutter_hymnExeVersion: '0.4',
            flutter_hymnExeUrl: '/app/flutter_hymn.exe',
            flutter_hymnLinuxVersion: '0.4',
            flutter_hymnLinuxUrl: '/app/flutter_hymn.zip',
            flutter_hymnDmgVersion: '0.4',
            flutter_hymnDmgUrl: '/app/flutter_hymn.dmg',
            flutter_hymnIpaVersion: '0.4',
            flutter_hymnIpaUrl: '/app/flutter_hymn.ipa',
        };
    },
    computed: {

    },
    setup() {
        
    },
    created() {
        if (this.global.is_weixin()) {
            this.showWechat = true
        }
        var text = this.global.loadFile('url.json')
        console.log(text)
        var json = JSON.parse(text)
        if (json == null) return
        this.hymnApkVersion = json["hymn"]["apkVersion"]
        this.hymnApkUrl = json["hymn"]["apkUrl"]
        this.hymnExeVersion = json["hymn"]["exeVersion"]
        this.hymnExeUrl = json["hymn"]["exeUrl"]
        this.hymnAppxVersion = json["hymn"]["appxVersion"]
        this.hymnAppxUrl = json["hymn"]["appxUrl"]
        this.hymnDmgVersion = json["hymn"]["dmgVersion"]
        this.hymnDmgUrl = json["hymn"]["dmgUrl"]
        this.hymnIpaVersion = json["hymn"]["ipaVersion"]
        this.hymnIpaUrl = json["hymn"]["ipaUrl"]
        
        this.flutter_hymnApkVersion = json["hymn"]["flutter_apkVersion"]
        this.flutter_hymnApkUrl = json["hymn"]["flutter_apkUrl"]
        this.flutter_hymnHapVersion = json["hymn"]["flutter_hapVersion"]
        this.flutter_hymnHapUrl = json["hymn"]["flutter_hapUrl"]
        this.flutter_hapToolUrl = json["hymn"]["flutter_hapToolUrl"]
        this.flutter_hymnExeVersion = json["hymn"]["flutter_exeVersion"]
        this.flutter_hymnExeUrl = json["hymn"]["flutter_exeUrl"]
        this.flutter_hymnLinuxVersion = json["hymn"]["flutter_linuxVersion"]
        this.flutter_hymnLinuxUrl = json["hymn"]["flutter_linuxUrl"]
        this.flutter_hymnDmgVersion = json["hymn"]["flutter_dmgVersion"]
        this.flutter_hymnDmgUrl = json["hymn"]["flutter_dmgUrl"]
        this.flutter_hymnIpaVersion = json["hymn"]["flutter_ipaVersion"]
        this.flutter_hymnIpaUrl = json["hymn"]["flutter_ipaUrl"]
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        openHref (category, url) {
            window.location.href = url
            this.$gtag.event('button', { 'event_category': category, 'event_label': url })
            this.$ba.trackEvent(category, 'button', url, '')
        },
    },
    components: {

    },
};
</script>

<style scoped>

.content {
    display:inline-block;
    text-align: left;
    max-width: 800px;
}
.wechat {
    position:relative;
    padding-top:20px;
    padding-bottom: 10px;
    color: black;
    background-color: #ebe7e7;
    font-size: 22px;
    text-align: center;
}
.red {
    color: red;
}
.description {
    color: gray;
    padding: 10px;
    -webkit-overflow-scrolling:touch;
    background-color:#efeff4;
}
.info {
    color: gray;
}
.os {
    padding: 10px;
}
p {
    margin: 0px;
}
a-divider {
    height: 1px;
}
.divider {
    width: 100%;
    height: 1px;
    position: relative;
    background-color: #eeeeee;
}
</style>
